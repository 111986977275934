<template>
  <!--
    The view for the Peak Login Data
  -->
  <div class="peakLoginData">
    <PeakLoginDataList />
  </div>
</template>

<script>
export default {
  name: "PeakLoginDataView",
  components: {
    PeakLoginDataList: () => import('@/components/SoftwareComponent/PeakLoginDataList.vue')
  }
}
</script>